// Typography
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-style: normal;
    margin-top: 0;
    line-height: 1;
    &:last-child {
        margin-bottom: 0;
    }
}

h2{
    font-size: 38px;
    margin: 0;
}
