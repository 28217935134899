.main-page{
    .intro{
        &__lang{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: em(25);
            &-item{
                min-width: 260px;
                margin: 0 em(10);
            }
            .btn__wrap{
                padding: em(7.5) em(18.8) em(7.5) em(7.5);
            }
            .btn__icon{
                margin-left: 0;
                margin-right: em(12);
            }
        }
    }
}