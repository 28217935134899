.print-page{
    .print{
        &__pack{
            @include css-lock(588, 800, 1366, 1920, width);
            margin: em(30) auto em(25);
            text-align: center;
            &-image{
                -webkit-print-color-adjust: exact !important;
                /* Chrome, Safari, Edge */
                color-adjust: exact !important;
                /*Firefox*/
                padding: 20%;
                position: relative;
                overflow: hidden;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-position: top center;
                border-radius: 8px;
                &::before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    content: '';
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 78.99%, #000000 100%);
                    opacity: 0.5;
                }
            }
            &-title{
                width: 100%;
                height: auto;
                position: relative;
                z-index: 1;
                font-family: 'Amarillo';
                line-height: 2.2;
                color: #cb2f50;
                white-space: nowrap;
                .name{
                    display: inline-block;
                    text-transform: capitalize
                }
                .article {
                    display: inline-block;
                    line-height: 3em;
                    vertical-align: text-bottom;
                    font-size: 0.7em;
                }
            }
        }
        &__btn{
            margin: 0 auto;
            .btn__wrap {
                padding: em(21) em(40);
            }
            .btn__icon{
                margin-left: 0;
            }
        }
        .link{
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: fit-content;
            margin: em(10) auto 0;
            text-decoration: none;
            color: currentColor;
            padding: em(15);
            transition: opacity .3s ease-in-out;
            img{
                margin-right: em(10);
            }
            &:hover{
                opacity: .5;
            }
        }
    }
    @media print {
        background: none;
        padding: 0;

        .layout{
            min-height: auto;
        }

        header, h2, button, .link, .link span, p{
            display: none;
        }

        .print__pack {
          position: fixed;
          width: 503px !important;
          height: 200px !important;
          margin: 0;
          top: calc(50% - 100px);
          left: calc(50% - 251.5px);
          
          &-image{
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
                &::before{
                    display: none;
                }
            }
            .textFitted {
              font-size: 38px!important;
            }
        }

        .print > .link {
          display: none!important;
        }

        // * {
        //   background: transparent !important;
        // }
    }
}

// @media print {  
//   @page {
//     size: 503px 200px;
//     margin: 100px;
//   }

//   body {
//     max-height: 200px;
//     max-width: 503px;
//     overflow: hidden;
//   }
// }
