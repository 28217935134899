/* The container must be positioned relative: */
.custom-select {
    position: relative;
    width: 100%;
    border-radius: 30px;
    font-size: 30px;
    background-color: $color-main;
    select{
        display: none;
    }
}

.select-selected {
    padding: em(20);
    /* Style the arrow inside the select element: */
    &:after {
        position: absolute;
        content: "";
        top: 45%;
        right: em(20);
        width: 16px;
        height: 16px;
        border: 1px solid transparent;
        border-color: $color-red $color-red transparent transparent;
        transform: translateY(-50%) rotate(135deg);
    }
    /* Point the arrow upwards when the select box is open (active): */
    &.select-arrow-active:after {
        top: 55%;
        border-color: transparent transparent $color-red $color-red;
    }
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
    cursor: pointer;
}

/* Style items (options): */
.select-items {
    padding: em(14) 0;
    margin-top: 1px;
    position: absolute;
    overflow: hidden;
    background-color: $color-main;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: 0px 40px 64px $color-red;
    border-radius: 30px;
    div{
        padding: em(5) em(14);
    }
}

/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

.select-items div:hover,
.same-as-selected {
    background-color: $color-gold;
}