@font-face {
    font-family: 'Zona Pro';
    src: url('../../fonts/ZonaPro/ZonaPro-Bold.eot');
    src: url('../../fonts/ZonaPro/ZonaPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/ZonaPro/ZonaPro-Bold.woff2') format('woff2'),
        url('../../fonts/ZonaPro/ZonaPro-Bold.woff') format('woff'),
        url('../../fonts/ZonaPro/ZonaPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Amarillo';
    src: url('../../fonts/Amarillo/Amarillo.eot');
    src: url('../../fonts/Amarillo/Amarillo.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Amarillo/Amarillo.woff2') format('woff2'),
        url('../../fonts/Amarillo/Amarillo.woff') format('woff'),
        url('../../fonts/Amarillo/Amarillo.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}