.cookie-page{
    .packs__list{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .pack{
        &__item{
            display: block;
            text-decoration: none;
            margin-top: em(30);
            padding: 0 em(15);
            width: 30%;
            text-align: center;
            transition: opacity .3s ease-in-out;
            &:hover{
                opacity: .85;
            }
            p{
                color: $color-main;
                margin: em(12) 0 0;
            }
            &:first-child{
                .pack__item-title{
                    color: #c5413a;
                }
            }
            &:nth-child(2){
                .pack__item-title {
                    color: #cb2f50;
                }
            }
            &:nth-child(3){
                .pack__item-title {
                    color: #51453b;
                }
            }
            &:nth-child(4) {
                .pack__item-title {
                    color: #93c77c;
                }
            }
            &:last-child {
                .pack__item-title {
                    color: #51453b;
                }
            }
            &-image{
                padding: 11% 4% 9.3%;
                position: relative;
                overflow: hidden;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-position: top center;
                border-radius: 8px;
                @include css-lock(116,140,1366,1920,height);
            }
            &-title{
                width: 100%;
                height: auto;
                font-family: 'Amarillo';
                line-height: 2.2;
                white-space: nowrap;
                overflow-wrap: normal;
                word-wrap: normal;
                word-break: normal;
                line-break: auto;
                -webkit-hyphens: none;
                hyphens: none;
                .name {
                    display: inline-block;
                    text-transform: capitalize
                }
                .article{
                    display: inline-block;
                    line-height: 2.5em;
                    vertical-align: text-bottom;
                    font-size: 0.75em;
                }
            }
        }
    }
}