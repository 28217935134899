.btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: em(8) em(18) em(8) em(8);
    border-radius: 80px;
    text-decoration: none;
    color: currentColor;
    border: none;
    transition: opacity .3s ease-in-out;
    &[disabled="disabled"]{
        opacity: .6;
    }
    &:not([disabled="disabled"]):hover{
        opacity: .6;
    }
    &__icon{
        margin: 0 em(15);
    }
    &__wrap{
        width: 100%;
        display: flex;
        align-items: center;
        @extend %bg-radial-red;
        border-radius: 80px;
    }
    &.--outline{
        padding: em(12) em(18) em(12) em(0);
        border: 1px solid rgba(255, 255, 255, 0.4);
        .btn__icon{
            padding: 0 em(12) 0 em(15);
            margin: 0;
        }
    }
    &.--primary{
        padding: em(4.5);
        @extend %bg-radial-gold;
        font-size: 24px;
        box-shadow: 0px 40px 64px -4px rgba(138, 15, 6, 0.5);
    }
}