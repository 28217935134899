.form-page{
    .form{
        color: $color-red;
        width: 100%;
        max-width: 600px;
        margin: em(25) auto 0;
        &__row{
            width: 100%;
        }
        &__input{
            width: 100%;
            height: 100%;
            border-radius: 30px;
            font-size: 30px;
            border: none;
            outline: none;
            padding: em(18.5);
            margin-top: em(14);
            transition: all .3s ease-in-out;
            
            &::-webkit-input-placeholder {
                color: $color-red;
                opacity: .5;
            }
            /* Firefox 19+ */
            &::-moz-placeholder {
                color: $color-red;
                opacity: .5;
            }
            /* Firefox 18- */
            &:-moz-placeholder {
                color: $color-red;
                opacity: .5;
            }
            &:-ms-input-placeholder {
                color: $color-red;
                opacity: .5;
            }
        }
        &__submit{
            color: $color-main;
            margin: em(30) auto 0;
            .btn__wrap{
                padding: em(19.5) em(15) em(19.5) em(25);
            }
        }
    }
    &.input-hidden{
        .form__input{
            width: 0;
            height: 0;
            margin-top: 0;
            padding: 0;
        }
    }
}