.header {
    position: relative;
    width: 100%;
    &__logo {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: fit-content;
    }
    &__container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: em(40) em(40) 0;
    }
    &__lang{
        display: flex;
        align-items: center;
        &-item{
            .btn__icon{
                width: 32px;
                height: auto;
            }
            &.--active{
                pointer-events: none;
                color: $color-red;
                @extend %bg-radial-gold;
                .btn__icon{
                    margin: 0 em(8) 0 0;
                }
            }
        }
    }
}
